<template>
	<div class="search-panel" :class="type">
		<div class="search-panel__container search-panel-wrapper">
			<or-drop-down class="search-panel__item">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholder="$t('general.price')"
						icon-before="price"
						icon-after="arrow"
						v-model="selectedTextForm.price"
						class="search-panel__input main-type"
						:class="{ 'active-input': active }"
					/>
				</template>
				<template v-slot:content>
					<PriceBlock
						class="search-panel__drop-content"
						:defaultValues="{
							min: form.price_from,
							max: form.price_to
						}"
						@update="updatePrice"
					/>
				</template>
			</or-drop-down>

			<or-drop-down class="search-panel__item">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholder="$t('general.square')"
						:prefix="currentSquareSymbol"
						icon-after="arrow"
						v-model="selectedTextForm.square"
						class="search-panel__input main-type"
						:class="{ 'active-input': active }"
					/>
				</template>
				<template v-slot:content>
					<Square
						class="search-panel__drop-content"
						:defaultValues="{
							min: form.area_from,
							max: form.area_to
						}"
						@update="updateSquare"
					/>
				</template>
			</or-drop-down>

			<or-drop-down class="search-panel__item">
				<template v-slot:toggle="{ active }">
					<or-textarea
						readonly
						:resize="false"
						:placeholder="$t('general.propertyType')"
						v-model="selectedTextForm.property"
						icon-before="property"
						icon-after="arrow"
						class="search-panel__input main-type placeholder-wrap"
						:class="{
							'active-input': active,
							'have-content': selectedTextForm.property?.length
						}"
					/>
				</template>
				<template v-slot:content>
					<PropertyList
						class="search-panel__drop-content"
						:is-hidden-on-click="false"
						:default-values="form.type_id"
						@select="selectPropertyType"
					/>
				</template>
			</or-drop-down>

			<or-drop-down class="search-panel__item">
				<template v-slot:toggle="{ active }">
					<or-textarea
						readonly
						:resize="false"
						:placeholder="$t('general.roomsBaths')"
						icon-before="rooms"
						v-model="selectedTextForm.roomsBaths"
						icon-after="arrow"
						class="search-panel__input main-type placeholder-wrap"
						:class="{
							'active-input': active,
							'have-content': selectedTextForm.roomsBaths
						}"
					/>
				</template>
				<template v-slot:content>
					<CountRoomsBaths
						class="search-panel__drop-content search-panel__drop-content--rooms-baths"
						:defaultValues="{
							baths: form.bathroom_count,
							rooms: form.bedroom_count
						}"
						@update="selectRoomsBaths"
					/>
				</template>
			</or-drop-down>

			<or-button @click="submit" class="search-panel__submit search-panel-btn">
				{{ $t('general.search') }}
			</or-button>

			<or-drop-down class="search-panel__item">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholder="$t('general.project')"
						icon-before="project"
						icon-after="arrow"
						v-model="selectedTextForm.project"
						class="search-panel__input main-type"
						:class="{ 'active-input': active }"
					/>
				</template>
				<template v-slot:content>
					<ProjectsList
						class="search-panel__drop-content"
						:select-area="form.region"
						:selectProject="{ slug: form.pr_slug }"
						:projects="projects"
						@select="selectProject"
					/>
				</template>
			</or-drop-down>

			<or-drop-down class="search-panel__item" :disabled="isAreaDisabled">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholder="$t('general.area')"
						icon-before="location"
						icon-after="arrow"
						:disabled="isAreaDisabled"
						v-model="selectedTextForm.region"
						class="search-panel__input main-type"
						:class="{ 'active-input': active }"
					/>
				</template>
				<template v-slot:content>
					<SearchList
						class="search-panel__drop-content"
						:regions="regions"
						:selectedUser="form.region"
						@select="selectRegion"
					/>
				</template>
			</or-drop-down>

			<div class="search-panel__item advanced-statuses">
				<ul class="advanced-statuses__list">
					<li
						v-for="item in $store.state.statusesAd"
						:key="item.slug"
						class="advanced-statuses__item"
					>
						<label :class="{ active: form.status.includes(item.slug) }">
							{{ item.title }}
							<input
								type="checkbox"
								v-model="form.status"
								:value="item.slug"
								name="advanced-statuses"
								hidden
							/>
						</label>
					</li>
				</ul>
			</div>

			<or-button
				v-if="isShowClearBtn"
				@click="clear"
				kind="solid-border"
				class="search-panel__submit search-panel-btn-clear"
			>
				{{ $t('general.clear') }}
			</or-button>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import debounce from 'lodash/debounce'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import { formatCash, formatNumber } from '@/utils/formatMoney'
import { simbol } from '@/enums/moneyСollections.js'
import { squareSymbols } from '@/enums/areaCollections.js'

export default {
	props: {
		type: {
			type: String,
			default: ''
		},
		isShowClearBtn: {
			type: Boolean,
			default: false
		}
	},
	components: {
		SearchList: defineAsyncComponent(() => import('./SearchList.vue')),
		ProjectsList: defineAsyncComponent(() => import('./ProjectsList.vue')),
		PropertyList: defineAsyncComponent(() => import('./PropertyList.vue')),
		CountRoomsBaths: defineAsyncComponent(() =>
			import('./CountRoomsBaths.vue')
		),
		PriceBlock: defineAsyncComponent(() => import('./Price.vue')),
		Square: defineAsyncComponent(() => import('./Square.vue'))
	},
	data() {
		return {
			form: {
				region: null,
				pr_slug: null,
				type_id: null,
				bathroom_count: null,
				bedroom_count: null,
				price_from: null,
				price_to: null,
				status: [],
				area_from: null,
				area_to: null,
				isSelectArea: false // чтьобы понимать, когда именно мы выбрали регион
			},
			selectedTextForm: {
				region: null,
				property: null,
				roomsBaths: null,
				price: null,
				project: null
			},
			propertyTypes: [],
			regions: [],
			projects: []
		}
	},
	async beforeMount() {
		this.getRegionList()
		await this.getProjectList()
	},
	mounted() {
		const qrs = this.$route.query

		Object.keys(this.form).forEach((itm) => {
			if (itm === 'status' && qrs[itm]?.length) {
				this.form.status = qrs[itm].split(',')
			} else if (qrs[itm]) {
				this.form[itm] = qrs[itm]
			}
		})

		// Название города и типа записываем в query чтобы не подгружать
		if (qrs.region) {
			try {
				const regions = JSON.parse(qrs.region)
				this.form.region = regions
				this.selectedTextForm.region = regions
					?.map((itm) => itm.title)
					.join(', ')
			} catch (e) {}
		}

		if (qrs.project) {
			this.selectedTextForm.project = qrs.project
		}

		// Для propert type выставляем выбранные значения
		if (this.form.type_id) {
			const types = this.form.type_id.split(',')
			this.propertyTypes = this.$store.state.adsType.filter((itm) =>
				types.includes(itm.id.toString())
			)

			this.selectedTextForm.property = this.propertyTypes
				.map((itm) => itm.title)
				.join(', ')
		}
	},
	watch: {
		form: {
			handler(payload) {
				if (!payload.bedroom_count && !payload.bathroom_count) {
					this.selectedTextForm.roomsBaths = null
				} else {
					const windowScreen = window.screen.width
					if (windowScreen > 1180) {
						this.selectedTextForm.roomsBaths = `${
							payload.bedroom_count || 0
						} rooms;\n${payload.bathroom_count || 0} baths`
					} else {
						this.selectedTextForm.roomsBaths = `${
							payload.bedroom_count || 0
						} rooms; ${payload.bathroom_count || 0} baths`
					}
				}

				if (payload.price_from || payload.price_to) {
					this.selectedTextForm.price = `${
						payload.price_from
							? simbol[this.$store.state.activeMoney] +
							  formatCash(+payload.price_from || 0)
							: 'Min'
					} — ${
						payload.price_to
							? simbol[this.$store.state.activeMoney] +
							  formatCash(+payload.price_to || 0)
							: 'Max'
					}`
				} else this.selectedTextForm.price = null

				if (payload.area_from || payload.area_to) {
					this.selectedTextForm.square = `${
						payload.area_from ? formatNumber(+payload.area_from) : 'Min'
					} — ${payload.area_to ? formatNumber(+payload.area_to) : 'Max'}`
				} else this.selectedTextForm.square = null
			},
			deep: true
		}
	},
	computed: {
		isAreaDisabled() {
			return !!this.form.pr_slug
		},
		currentSquareSymbol() {
			return squareSymbols[this.$store.state.areaUnit]
		}
	},
	methods: {
		getRegionList() {
			createRequest(requestConfigs.GETRegionList, {
				queryPayload: { per_page: 20, order_by: 'sort', direction: 'asc' }
			}).then((result) => {
				this.regions = result.response.items
			})
		},
		getProjectList: debounce(function () {
			const payload = {
				per_page: 50
			}

			const regions = this.form.region?.map((itm) => itm.slug).join(',')

			if (regions?.length && regions.length < 255 && this.form.isSelectArea) {
				payload.region_slug = regions
			}

			createRequest(requestConfigs.GETProjectList, {
				queryPayload: { ...payload }
			}).then((result) => {
				this.projects = result.response.items
			})
		}, 1000),
		selectProject(payload) {
			if (Object.keys(payload).length) {
				this.form.pr_slug = payload.slug
				this.selectedTextForm.project = payload.title

				// Обновляем регион
				this.selectRegion([payload.region], false)
			} else {
				this.form.pr_slug = payload.slug
				this.selectedTextForm.project = payload.title

				this.form.region = null
				this.selectedTextForm.region = null
			}
		},
		async selectRegion(payload, flag = true) {
			// flag - обновлять ли проект
			this.form.region = payload?.map((itm) => ({
				id: itm.id,
				title: itm.title,
				price_from: itm.price_from,
				slug: itm.slug,
				announcement_count: itm.announcement_count
			}))
			this.selectedTextForm.region = payload?.map((itm) => itm.title).join(', ')

			if (flag) {
				// Понять, когда мы выбрали регионы и по ним фильтровать проекты
				this.form.isSelectArea = true
				this.getProjectList()
			}
		},
		selectPropertyType(payload) {
			const idx = this.propertyTypes?.findIndex((itm) => itm.id === payload.id)

			if (idx < 0) {
				this.propertyTypes.push(payload)
			} else {
				this.propertyTypes.splice(idx, 1)
			}

			this.form.type_id = this.propertyTypes.map((itm) => itm.id).join(',')
			this.selectedTextForm.property = this.propertyTypes
				.map((itm) => itm.title)
				.join(', ')
		},
		selectRoomsBaths({ baths, rooms }) {
			let rms = [...rooms]
			if (rms?.length) {
				rms = rms.map((itm) => (itm === '0' ? this.$t('general.studio') : itm))
			}

			this.form.bathroom_count = baths.join(',')
			this.form.bedroom_count = rms.join(',')
		},
		updateSquare({ min, max }) {
			this.form.area_from = min
			this.form.area_to = max
		},
		updatePrice({ min, max }) {
			this.form.price_from = min
			this.form.price_to = max
		},
		async clear() {
			Object.keys(this.form).forEach((key) => {
				if (key === 'status') {
					this.form.status = []
				} else {
					this.form[key] = null
				}
			})

			Object.keys(this.selectedTextForm).forEach((key) => {
				this.selectedTextForm[key] = null
			})

			this.propertyTypes = []
			this.regions = []
			this.projects = []

			await this.$router.push({
				query: {},
				params: { lang: this.$store.state.uiLanguage }
			})

			this.getRegionList()
			this.getProjectList()

			this.$emit('update')
		},
		async submit() {
			const payload = {}
			// Не трогать ключи из расширенного поиска
			// Refactoring
			const advancedSearchKeys = [
				'phrases',
				'furnished',
				'area_from',
				'area_to',
				'exeptions',
				'has_parking',
				'with_balcony',
				'year_const'
			]
			advancedSearchKeys.forEach((itm) => {
				if (this.$route.query[itm]) {
					payload[itm] = this.$route.query[itm]
				}
			})

			Object.entries(this.form).forEach(([key, value]) => {
				if (key === 'status' && value?.length) {
					payload.status = value.join(',')
				} else if (value) {
					payload[key] = value
				}
			})

			if (this.type === 'page') {
				await this.$router.push({
					params: { lang: this.$store.state.uiLanguage },
					query: {
						...payload,
						region: JSON.stringify(payload.region),
						project: this.selectedTextForm?.project
					}
				})

				this.$emit('update')
			} else {
				this.$router.push({
					name: 'Search',
					params: { lang: this.$store.state.uiLanguage },
					query: {
						...payload,
						region: JSON.stringify(payload.region),
						project: this.selectedTextForm.project
					}
				})
			}
		}
	}
}
</script>

<style lang="scss">
.search-panel {
	padding: 3%;
	background-color: white;

	&__input {
		cursor: pointer;

		&.placeholder-wrap {
			position: relative;
			.main-textarea-content {
				display: flex;
				align-items: center;
				padding-top: 8px;
				resize: none;
				overflow: hidden;
				-webkit-line-clamp: 2;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				&::placeholder {
					white-space: pre-line;
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
					max-width: 60%;
				}
			}
		}
	}

	&.page {
		display: flex;
		gap: 10px;
		justify-content: space-between;
		padding: 0;

		@media (max-width: 768px) {
			flex-direction: column;
			padding-bottom: 20px;
		}

		.search-panel-wrapper {
			width: 100%;
		}
	}

	&.search-panel-home {
		.search-panel-wrapper {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
			grid-gap: 10px;

			@media (max-width: 1180px) {
				grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
			}

			.advanced-statuses {
				grid-column: 3 / 6;

				@media (max-width: 1180px) {
					padding: 20px 0;
					grid-column: unset;
				}
			}

			.button {
				width: 100%;
				grid-row: 1/1;
				grid-column: 5/6;

				@media (max-width: 1180px) {
					grid-row: 8/9;
					grid-column: 1/2;
				}
			}
		}
	}

	&.search-panel-search {
		.search-panel-wrapper {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
			grid-gap: 10px;

			@media (max-width: 1180px) {
				grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
			}

			.advanced-statuses {
				grid-column: 3 / 5;

				@media (max-width: 1180px) {
					padding: 20px 0;
					grid-column: unset;
				}
			}

			.search-panel-btn {
				width: 100%;
				grid-row: 1/1;
				grid-column: 5/6;
				@media (max-width: 1180px) {
					grid-row: 9/9;
					grid-column: 1/2;
				}
			}

			.advanced-statuses__list {
				justify-content: center;

				@media (max-width: 1180px) {
					justify-content: flex-start;
				}
			}
		}
	}

	&__drop-content {
		margin-top: 10px;

		&--last {
			margin-left: -170px;

			@media (max-width: 768px) {
				margin-left: 0;
			}
		}
	}

	&__bottom {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	&__checkboxes {
		display: flex;
		flex-direction: row;
		align-items: center;
		grid-gap: 10px;
		flex-wrap: wrap;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
}
</style>

<style lang="scss" scoped>
.advanced-statuses {
	&__list {
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		align-items: center;
	}

	&__item {
		display: flex;

		label {
			position: relative;
			display: block;
			flex-shrink: 0;
			padding: 5px 10px;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: #000000;
			transition: all 0.3s linear;
			border-radius: 4px;
			cursor: pointer;
			border: 1px solid #c99d56;

			&.active {
				background-color: #c99d56;
				color: #fff;
			}

			svg {
				position: absolute;
				right: 12px;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		}
	}
}
</style>
