<template>
	<div class="advanced-search">
		<Transition name="slide-fade">
			<div v-if="isShow" class="advanced-search__container">
				<div class="advanced-search__keyword-exeptions">
					<div class="advanced-search__tags advanced-tags">
						<Vue3TagsInput
							:tags="form.phrases"
							:limit="10"
							@on-focus="keywordsInFocus = true"
							@on-blur="keywordsInFocus = false"
							@on-tags-changed="handleChangeTag"
							:placeholder="$t('general.search_keywords')"
							:class="{ 'hidden-placeholder': form.phrases?.length }"
						/>

						<ul class="advanced-search__example-keywords example-keywords">
							<li v-for="item in exampleKeywords" :key="item">
								<button
									type="button"
									class="btn example-keywords__btn"
									@click="addExampleKeyword(item)"
									:class="{ active: form.phrases?.includes(item) }"
								>
									{{ $t(item) }}
								</button>
							</li>
						</ul>
					</div>

					<or-button
						kind="solid-border"
						class="advanced-search__toggle-exeptions"
						@click="selectExceptions"
						:height="50"
					>
						{{ $t('general.exeptions') }}
						{{ countExeptions ? `(${countExeptions})` : null }}
					</or-button>
				</div>

				<div class="advanced-search__another-filters advanced-another">
					<or-drop-down
						class="advanced-another__item advanced-another__item--year-const"
					>
						<template v-slot:toggle="{ active }">
							<or-input
								readonly
								:placeholder="$t('ads.yearConst')"
								icon-after="arrow"
								class="search-panel__input main-type"
								v-model="yearsTexts"
								:class="{ 'active-input': active }"
							/>
						</template>
						<template v-slot:content>
							<YearsList
								:is-hidden-on-click="false"
								:default-values="form.year_const"
								@select="selectYearConst"
							/>
						</template>
					</or-drop-down>

					<div class="advanced-another__item advanced-list">
						<ul class="advanced-list__list furnished-list">
							<li
								v-for="item in config.furnished"
								:key="item.value"
								class="advanced-list__item"
							>
								<label :class="{ active: item.value === form.furnished }">
									{{ $t(item.title) }}
									<input
										type="radio"
										v-model="form.furnished"
										:value="item.value"
										hidden
									/>
								</label>
							</li>
						</ul>
						<div class="advanced-list__info">
							{{ $t('ads.furnished') }}
						</div>
					</div>

					<div class="advanced-another__item advanced-list">
						<ul class="advanced-list__list furnished-list">
							<li
								v-for="item in config.parking"
								:key="item.value"
								class="advanced-list__item"
							>
								<label :class="{ active: item.value === form.has_parking }">
									{{ $t(item.title) }}
									<input
										type="radio"
										v-model="form.has_parking"
										:value="item.value"
										hidden
									/>
								</label>
							</li>
						</ul>
						<div class="advanced-list__info">
							{{ $t('ads.parking') }}
						</div>
					</div>

					<div class="advanced-another__item advanced-list">
						<ul class="advanced-list__list furnished-list">
							<li
								v-for="item in config.balcony"
								:key="item.value"
								class="advanced-list__item"
							>
								<label :class="{ active: item.value === form.with_balcony }">
									{{ $t(item.title) }}
									<input
										type="radio"
										v-model="form.with_balcony"
										:value="item.value"
										hidden
									/>
								</label>
							</li>
						</ul>
						<div class="advanced-list__info">
							{{ $t('ads.balcony') }}
						</div>
					</div>
				</div>
			</div>
		</Transition>
		<button
			type="button"
			class="advanced-search__toggle"
			:class="{ active: isShow }"
			@click="isShow = !isShow"
		>
			{{ $t('general.advancedSearch') }}
			<or-icon type="arrow" />
		</button>
	</div>
</template>

<script>
import Vue3TagsInput from 'vue3-tags-input'
import YearsList from './components/YearsList.vue'

export default {
	name: 'AdvancesSearch',
	components: {
		YearsList,
		Vue3TagsInput
	},
	data() {
		return {
			form: {
				// Когда один елемент делаем его массивом
				phrases: this.$route.query.phrases
					? typeof this.$route.query.phrases === 'string'
						? [this.$route.query.phrases]
						: this.$route.query.phrases
					: [],
				furnished: this.$route.query.furnished || null,
				has_parking: this.$route.query.has_parking || null,
				with_balcony: this.$route.query.with_balcony || null,
				year_const: this.$route.query.year_const || null,
				area_from: this.$route.query.area_from || null,
				area_to: this.$route.query.area_to || null,
				exeptions: []
			},
			config: {
				furnished: [
					{ title: 'general.any', value: null },
					{ title: 'general.yes', value: '1' },
					{ title: 'general.no', value: '0' }
				],
				parking: [
					{ title: 'general.yes', value: '1' },
					{ title: 'general.any', value: null }
				],
				balcony: [
					{ title: 'general.yes', value: '1' },
					{ title: 'general.any', value: null }
				]
			},
			yearsList: [],
			yearsTexts: '',
			keywordsInFocus: false,
			isShow: false
		}
	},
	mounted() {
		if (this.form.year_const?.length) {
			this.yearsList = this.form.year_const.split(',')
			this.yearsTexts = this.yearsList
				.map((itm) => itm.charAt(0).toUpperCase() + itm.slice(1))
				.join('; ')
		}
	},
	computed: {
		exampleKeywords() {
			return [
				this.$t('general.keyword_pool'),
				this.$t('general.keyword_seaview'),
				this.$t('general.keyword_waterfront'),
				this.$t('general.keyword_lagoon'),
				this.$t('general.keyword_metro')
			]
		},
		countExeptions() {
			return (
				this.$store.state.exeptions.estates.length +
				this.$store.state.exeptions.projects.length +
				this.$store.state.exeptions.developers.length
			)
		}
	},
	methods: {
		selectYearConst(payload) {
			const id = payload.id.toString()
			if (!this.yearsList.includes(id)) {
				this.yearsList.push(id)
			} else {
				this.yearsList = this.yearsList.filter((itm) => itm !== id)
			}

			this.yearsTexts = this.yearsList
				.map(
					(itm) =>
						itm.toString().charAt(0).toUpperCase() + itm.toString().slice(1)
				)
				.join('; ')
			this.form.year_const = this.yearsList.join(',')
		},
		addExampleKeyword(payload) {
			if (!this.form.phrases?.includes(payload)) {
				this.form.phrases.push(payload)
			}
		},
		handleChangeTag(tags) {
			this.form.phrases = tags
		},
		selectExceptions() {
			this.$store.commit('showModal', {
				name: 'SearchExeptions',
				payload: {
					callback: (payload) => {}
				}
			})
		}
	},
	watch: {
		'$route.query.phrases'(payload) {
			// Когда нажимаем кнопку clear
			if (payload === undefined) {
				this.form.phrases = []
			}
		},
		form: {
			handler() {
				const payload = {}

				// Не трогать ключи из основного фильтра
				// Refactoring
				const advancedSearchKeys = [
					'region',
					'pr_slug',
					'type_id',
					'bathroom_count',
					'bedroom_count',
					'price_from',
					'price_to',
					'status',
					'area_from',
					'area_to',
					'isSelectArea'
				]
				advancedSearchKeys.forEach((itm) => {
					if (this.$route.query[itm]) {
						payload[itm] = this.$route.query[itm]
					}
				})

				Object.entries(this.form).forEach(([key, value]) => {
					if (key === 'phrases' && value?.length) {
						payload[key] = value
					} else if (value !== null) {
						payload[key] = value
					}
				})

				this.$router.push({
					query: { ...payload },
					params: { lang: this.$store.state.uiLanguage }
				})
			},
			deep: true
		}
	}
}
</script>

<!--<style lang="scss">-->
<!--.advanced-search {-->
<!--	position: relative;-->

<!--	.slide-fade-enter-active {-->
<!--		transition: all 0.2s ease-out;-->
<!--	}-->

<!--	.slide-fade-leave-active {-->
<!--		transition: all 0.15s ease-out;-->
<!--	}-->

<!--	.slide-fade-enter-from,-->
<!--	.slide-fade-leave-to {-->
<!--		transform: translateY(-20px);-->
<!--		opacity: 0;-->
<!--	}-->

<!--	&__container {-->
<!--		margin-top: 30px;-->
<!--		padding: 20px;-->
<!--		border-color: rgba(0, 0, 0, 0.1);-->
<!--		border-width: 1px 0px;-->
<!--		border-style: solid;-->

<!--		@media screen and (max-width: 768px) {-->
<!--			position: relative;-->
<!--			margin-top: 0;-->
<!--			padding: 20px 10px;-->
<!--			padding-bottom: 98px;-->
<!--		}-->
<!--	}-->

<!--	&__keyword-exeptions {-->
<!--		display: flex;-->
<!--		justify-content: space-between;-->
<!--		gap: 30px;-->
<!--		background: #fcfcfc;-->

<!--		@media (max-width: 768px) {-->
<!--			flex-wrap: wrap;-->
<!--		}-->

<!--		@media (max-width: 570px) {-->
<!--			justify-content: center;-->
<!--			flex-direction: column;-->
<!--			align-items: center;-->
<!--		}-->

<!--		button {-->
<!--			@media (max-width: 570px) {-->
<!--				width: 100%;-->
<!--			}-->
<!--		}-->
<!--	}-->

<!--	&__toggle {-->
<!--		position: absolute;-->
<!--		bottom: -58px;-->
<!--		left: 0;-->
<!--		right: 0;-->
<!--		display: inline-flex;-->
<!--		align-items: center;-->
<!--		grid-gap: 5px;-->
<!--		padding: 0;-->
<!--		max-width: 170px;-->
<!--		margin: 0 auto;-->
<!--		font-weight: 400;-->
<!--		font-size: 14px;-->
<!--		line-height: 18px;-->
<!--		color: #808080;-->
<!--		background-color: transparent;-->
<!--		border: 0;-->
<!--		cursor: pointer;-->
<!--		transition: color 0.8s cubic-bezier(1, 0.5, 0.8, 1);-->

<!--		@media (max-width: 550px) {-->
<!--			padding: 23px 0;-->
<!--			position: unset;-->
<!--			display: flex;-->
<!--			justify-content: center;-->
<!--			align-items: center;-->
<!--			border-bottom: 1px solid rgba(0, 0, 0, 0.1);-->
<!--			max-width: 100%;-->
<!--			width: 100%;-->
<!--			margin: 0;-->
<!--		}-->

<!--		i {-->
<!--			margin-left: 10px;-->

<!--			&:before {-->
<!--				transition: all 0.3s ease-out;-->
<!--			}-->
<!--		}-->

<!--		&.active {-->
<!--			color: #c99d56;-->
<!--			transition: color 0.3s ease-out;-->

<!--			i::before {-->
<!--				transform: rotate(180deg);-->
<!--				transition: all 0.3s ease-out;-->
<!--			}-->
<!--		}-->
<!--	}-->

<!--	&__toggle-exeptions {-->
<!--		min-width: 190px;-->

<!--		@media screen and (max-width: 768px) {-->
<!--			position: absolute;-->
<!--			bottom: 30px;-->
<!--			left: 0;-->
<!--			right: 0;-->
<!--		}-->
<!--	}-->

<!--	&__tags {-->
<!--		padding-bottom: 15px;-->
<!--	}-->
<!--}-->

<!--.advanced-another {-->
<!--	display: flex;-->
<!--	flex-wrap: wrap;-->
<!--	justify-content: space-between;-->

<!--	&__item {-->
<!--		&&#45;&#45;year-const {-->
<!--			width: 100%;-->
<!--			max-width: 405px;-->
<!--		}-->

<!--		&:nth-child(2),-->
<!--		&:nth-child(3) {-->
<!--			border-right: 1px solid rgba(0, 0, 0, 0.1);-->
<!--		}-->
<!--	}-->

<!--	@media screen and (max-width: 1024px) {-->
<!--		gap: 10px;-->
<!--	}-->

<!--	@media screen and (max-width: 912px) {-->
<!--		gap: 30px;-->

<!--		&__item {-->
<!--			&:nth-child(2),-->
<!--			&:nth-child(3) {-->
<!--				border-right: unset;-->
<!--			}-->
<!--		}-->
<!--	}-->

<!--	@media screen and (max-width: 768px) {-->
<!--		justify-content: flex-start;-->

<!--		&__item {-->
<!--			&&#45;&#45;year-const {-->
<!--				order: 4;-->
<!--				max-width: unset;-->
<!--				width: 110%;-->
<!--				margin: 0 -10px;-->
<!--			}-->
<!--		}-->
<!--	}-->
<!--}-->

<!--.advanced-tags {-->
<!--	position: relative;-->
<!--	width: 100%;-->

<!--	&__text {-->
<!--		padding-left: 10px;-->
<!--		font-weight: 400;-->
<!--		font-size: 12px;-->
<!--		line-height: 16px;-->
<!--		text-align: right;-->
<!--		color: #808080;-->
<!--	}-->

<!--	.v3ti {-->
<!--		position: relative;-->
<!--		display: block;-->
<!--		height: auto !important;-->
<!--		min-height: 48px;-->
<!--		margin: 0;-->
<!--		padding: 0 15px;-->
<!--		height: 48px;-->
<!--		box-shadow: none;-->
<!--		border: 1px solid rgba(0, 0, 0, 0.1);-->
<!--		border-radius: 4px;-->
<!--		transition: all 0.5s linear;-->

<!--		&:hover,-->
<!--		&:focus,-->
<!--		&:active {-->
<!--			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);-->
<!--			border-color: #c99d56;-->
<!--			transition: all 0.5s linear;-->
<!--		}-->

<!--		.v3ti-content {-->
<!--			gap: 3px;-->
<!--			align-items: center;-->
<!--			height: 100%;-->
<!--			border: 0;-->
<!--		}-->

<!--		.v3ti-tag {-->
<!--			margin: 10.5px 0;-->
<!--			height: 22px;-->
<!--			background-color: transparent;-->

<!--			span {-->
<!--				padding: 2px 5px;-->
<!--				font-weight: 400;-->
<!--				font-size: 14px;-->
<!--				line-height: 18px;-->
<!--				background-color: #c99d56;-->
<!--				border-radius: 2px 0px 0px 2px;-->
<!--			}-->

<!--			.v3ti-remove-tag {-->
<!--				display: block;-->
<!--				width: 22px;-->
<!--				height: 100%;-->
<!--				padding: 0;-->
<!--				background-color: #f2f2f2;-->

<!--				&:before {-->
<!--					display: block;-->
<!--					width: 100%;-->
<!--					height: 100%;-->
<!--					color: #c99d56;-->
<!--					font-size: 16px;-->
<!--					text-align: center;-->
<!--				}-->
<!--			}-->
<!--		}-->

<!--		.v3ti-new-tag {-->
<!--			padding: 0;-->
<!--			margin: 0;-->
<!--			height: 48px;-->
<!--		}-->

<!--		&.hidden-placeholder .v3ti-new-tag::placeholder {-->
<!--			opacity: 0;-->
<!--		}-->
<!--	}-->

<!--	.example-keywords {-->
<!--		margin-top: 5px;-->
<!--		display: flex;-->
<!--		flex-wrap: wrap;-->
<!--		padding: 10px;-->
<!--		gap: 10px;-->

<!--		&__btn {-->
<!--			padding: 5px 10px;-->
<!--			background: rgba(0, 0, 0, 0.03);-->
<!--			border-radius: 4px;-->
<!--			border: 1px solid rgba(0, 0, 0, 0.03);-->

<!--			&:hover,-->
<!--			&.active {-->
<!--				border-color: #c99d56;-->
<!--				background-color: transparent;-->
<!--			}-->
<!--		}-->

<!--		@media screen and (max-width: 768px) {-->
<!--			padding-bottom: 20px;-->
<!--			border-bottom: 1px solid rgba(0, 0, 0, 0.1);-->
<!--		}-->
<!--	}-->

<!--	@media screen and (max-width: 768px) {-->
<!--		padding-bottom: 0;-->
<!--		margin-bottom: 20px;-->
<!--	}-->
<!--}-->

<!--.advanced-areas {-->
<!--	display: flex;-->
<!--	justify-content: space-between;-->

<!--	&__input {-->
<!--		max-width: 100px;-->

<!--		&:first-child {-->
<!--			margin-right: 50px;-->
<!--		}-->
<!--	}-->
<!--}-->

<!--.advanced-list {-->
<!--	display: flex;-->
<!--	flex-wrap: wrap;-->
<!--	align-content: flex-start;-->
<!--	align-items: center;-->
<!--	grid-gap: 10px;-->
<!--	padding-top: 5px;-->

<!--	@media screen and (max-width: 768px) {-->
<!--		padding-top: 0;-->
<!--	}-->

<!--	@media (max-width: 570px) {-->
<!--		width: 100%;-->
<!--	}-->

<!--	&__list {-->
<!--		display: flex;-->
<!--		justify-content: space-between;-->
<!--		margin-right: 0;-->
<!--		margin-bottom: 6px;-->
<!--	}-->

<!--	&__item {-->
<!--		margin-right: 20px;-->

<!--		&:last-child {-->
<!--			margin-right: 0;-->
<!--		}-->

<!--		label {-->
<!--			position: relative;-->
<!--			display: flex;-->
<!--			align-items: center;-->
<!--			padding-right: 28px;-->
<!--			font-weight: 400;-->
<!--			font-size: 12px;-->
<!--			line-height: 16px;-->
<!--			text-align: right;-->
<!--			color: #808080;-->
<!--			cursor: pointer;-->

<!--			&:before {-->
<!--				content: '';-->
<!--				position: absolute;-->
<!--				right: 0;-->
<!--				width: 18px;-->
<!--				height: 18px;-->
<!--				border: 1px solid rgba(0, 0, 0, 0.1);-->
<!--				border-radius: 18px;-->
<!--			}-->

<!--			&.active:before {-->
<!--				background-color: #c99d56;-->
<!--			}-->

<!--			&:after {-->
<!--				content: '';-->
<!--				position: absolute;-->
<!--				top: 0;-->
<!--				bottom: 0;-->
<!--				right: 4px;-->
<!--				width: 11px;-->
<!--				margin: auto 0;-->
<!--				height: 7px;-->
<!--				background-image: url('~@/modules/search/assets/check.svg');-->
<!--				background-repeat: no-repeat;-->
<!--				background-size: 11px 7px;-->
<!--				z-index: 3;-->
<!--			}-->
<!--		}-->
<!--	}-->

<!--	&__info {-->
<!--		flex-basis: 100%;-->
<!--		font-weight: 400;-->
<!--		font-size: 12px;-->
<!--		line-height: 16px;-->
<!--		color: #808080;-->
<!--	}-->
<!--}-->
<!--</style>-->
